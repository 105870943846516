<template>
  <div class="container">
    <div class="row">
      <div class="col">
        <h3 class="fw-bold text-success">Create Client</h3>

        <div class="card border-0 shadow-none">
          <div class="card-body">
            <form @submit.prevent="addClient">
              <div class="row">
                <div class="col-md-6 mb-4">
                  <label for="">First name</label
                  ><input
                    type="text"
                    v-model="form.first_name"
                    class="form-control"
                    placeholder="Jane"
                  />
                </div>
                <div class="col-md-6 mb-4">
                  <label for="">Last name</label
                  ><input
                    type="text"
                    v-model="form.last_name"
                    class="form-control"
                    placeholder="Bloggs"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-md-6 mb-4">
                  <label for="">Email address</label
                  ><input
                    type="email"
                    v-model="form.email"
                    class="form-control"
                    placeholder="jane@bloggs.com"
                  />
                </div>
                <div class="col-md-6 mb-4">
                  <label for="">Phone number</label>
                  <phone-number-input
                    v-model="form.phone"
                    default-country-code="GB"
                    :preferred-countries="[
                      'GB',
                      'MT',
                      'GR',
                      'ES',
                      'FR',
                      'BE',
                      'DE',
                    ]"
                    :clearable="true"
                  ></phone-number-input>
                </div>
              </div>

              <div class="row">
                <div class="col-md-6 mb-4">
                  <label for="">Mobile number</label>
                  <phone-number-input
                    v-model="form.mobile"
                    default-country-code="GB"
                    :preferred-countries="[
                      'GB',
                      'MT',
                      'GR',
                      'ES',
                      'FR',
                      'BE',
                      'DE',
                    ]"
                    :clearable="true"
                  ></phone-number-input>
                </div>
              </div>

              <div class="row mb-4">
                <div class="col">
                  <label for="">Enquiry - optional - no email sent</label>
                  <textarea
                    v-model="form.enquiry"
                    class="form-control"
                    rows="4"
                    placeholder="Add an enquiry... (optional)"
                  ></textarea>
                </div>
              </div>

              <div class="row">
                <div class="col-auto ms-auto">
                  <button
                    class="btn btn-success"
                    :disabled="!form.first_name || !form.last_name || !form.email"
                  >
                    <i class="far fa-save me-2"></i>Save new client
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      form: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        mobile: "",
        enquiry: "",
      },
    };
  },
  methods: {
    addClient() {
      this.$axios
        .post(process.env.VUE_APP_API_URL + "/clients", this.form)
        .then(({ data }) => {
          this.$router.push("/clients/" + data.client_id);
        });
    },
  },
  mounted() {
    this.$store.sidebarOpen = false;
  },
};
</script>

<style>
</style>